import React from "react"

import * as R from "ramda"

import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete"

import TextField from "@material-ui/core/TextField"
import Box from "@material-ui/core/Box"
import { fieldToTextField } from "formik-material-ui"

import CancelIcon from "@material-ui/icons/Cancel"

import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import colors from "../theme/colors"

const useStyles = makeStyles(theme => ({
  root: {
    /* Hack until properly controlling these adornments is in place */
    "& .MuiAutocomplete-clearIndicator svg": {
      height: "1.2rem",
      width: "1.2em",
    },
  },
  tags: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  tag: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    color: colors.base.white,
    padding: "0.15rem 0.5rem",
    marginRight: "0.5rem",
    marginBottom: "0.25rem",
    borderRadius: "4px",
    overflow: "hidden",
    background: colors.grey[3],
    "& svg": {
      height: "1.1rem",
      width: "1.1rem",
      marginLeft: "0.35rem",
    },
  },
}))

//Inspired by https://github.com/mui-org/material-ui/issues/18331
const AutocompleteField = ({ textFieldProps, ...props }) => {
  const {
    form: { values, setTouched, setFieldValue },
    indexesLoaded
  } = props
  const { error, helperText, ...field } = fieldToTextField(props)
  const { name } = field

  const onChange = (_, value) => {
    return setFieldValue(name, value)
  }

  const onDelete = label => {
    setFieldValue(
      name,
      R.pathOr([], [name], values).filter(v => v.label !== label)
    )
  }

  const filterOptions = createFilterOptions({
    stringify: option => option.label,
    limit: 5,
  })

  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Autocomplete
        multiple
        {...props}
        {...field}
        onChange={(_, value) => onChange(_, value)}
        onBlur={() => setTouched({ [name]: true })}
        filterSelectedOptions
        noOptionsText={indexesLoaded ? 'Ei hakutuloksia' : 'Ladataan hakutuloksia, odota hetki...'}
        renderTags={() => null} // Hide the default tags
        popupIcon={null}
        filterOptions={filterOptions}
        renderInput={props => (
          <TextField
            {...props}
            {...textFieldProps}
            helperText={helperText}
            error={error}
          />
        )}
      />
      <Box className={classes.tags}>
        {R.pathOr([], [name], values).map((item, idx) => {
          return <Tag key={idx} onDelete={onDelete} label={item.label} />
        })}
      </Box>
    </Box>
  )
}

export default AutocompleteField

const Tag = ({ label, onDelete }) => {
  const classes = useStyles()

  return (
    <Box className={classes.tag}>
      <Typography variant="body2" component="span">
        {label}
      </Typography>
      <CancelIcon onClick={() => onDelete(label)} />
    </Box>
  )
}
