import React, { useState, useEffect, useContext } from "react"
import * as R from "ramda"
import { navigate } from "gatsby"

import SearchContext from "../../../contexts/SearchContext"

import toTitleCase from "../../../utils/toTitleCase"
import { generateURLFromFilters } from "../../../utils/urlSearchFilters"

import { Formik, Field } from "formik"
import { Button, FormControl, Checkbox, ListItemText } from "@material-ui/core"
import { TextField, CheckboxWithLabel, Select } from "formik-material-ui"
import AutocompleteField from "../../forms/AutocompleteField"

import MenuItem from "@material-ui/core/MenuItem"
import InputAdornment from "@material-ui/core/InputAdornment"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

import AddIcon from "@material-ui/icons/Add"
import RemoveIcon from "@material-ui/icons/Remove"

import { Container, Box, Grid, Collapse, Typography, makeStyles } from "@material-ui/core"

import Section from "../Section"

import colors from "../../theme/colors"

import searchBoxConfiguration from "../../../data/search-box-configuration.json"
import { styles } from "ansi-colors"
import { split } from "lodash"

const isClient = typeof window !== "undefined"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    //padding set on elemtent depending on props
    //margin set on elemtent depending on props
    background: colors.grey[0],
  },
  search: {
    margin: "auto",
    width: "100%",
    height: "100%",
    //borderRadius set on elemtent depending on props
    background: colors.grey[0],
    paddingTop: "2rem",
    paddingBottom: "2rem",
  },

  fieldLabel: {
    display: "flex",
    flexDirection: "column",
  },
  selectField: {
    marginTop: "8px",
    marginBottom: "4px",
    minWidth: "200px",
    "& svg": {
      color: colors.grey[1],
    },
  },
  selectItem: {
    marginTop: "0",
    marginBottom: "0",
  },
  placeholder: {
    color: colors.grey[2],
  },
  locationField: {},
  numberField: {
    width: "8em",
  },
  rangeIcon: {
    marginTop: "8px",
    color: colors.grey[2],
  },
  locationInputAdornment: {
    fontSize: "1.3rem",
    color: colors.grey[1],
  },
  sizeInputAdornment: {
    fontSize: "0.8rem",
  },
  tagCheckboxContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginBottom: "1rem",
    marginRight: "2.5rem",
  },
  criteriaHeader: {
    display: "block",
    marginBottom: "1rem",
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  tagCheckboxHeader: {
    fontSize: "0.9rem",
    marginBottom: "0.25rem",
    fontWeight: "bold",
  },
  buttonContainer: {
    marginTop: "2rem",
  },
  resetButtonContainer: {
    marginTop: "0.5rem",
  },
  toggleDetailedSearch: {
    cursor: "pointer",
    textTransform: "uppercase",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    color: colors.grey[2],
    "& svg": {
      height: "24px",
      width: "24px",
      marginRight: "0.3rem",
    },
  },
  submitButton: {
    width: "200px",
    "@media (min-width: 600px)": {
      width: "300px",
    },
  },
}))

/* This component is added to see if this improves performance */
const SearchBox = ({ searchContext, compLoaded, preloadIndex }) => {
  return <SearchBoxForm compLoaded={compLoaded} preloadIndex={preloadIndex} />
}

const SearchBoxForm = ({ fullWidth, compLoaded, preloadIndex }) => {
  /*
    Select multiple dropdown (for types)
  */
  /*const [selectedTypes, setSelectedTypes] = useState([]);

  const handleChangeSlectedTypes = (event) => {
    setSelectedTypes(event.target.value);
  };*/

  const searchContext = useContext(SearchContext)

  /*
   Controls for the form
  */
  const [detailedSearch, setDetailedSearch] = useState(false)

  const toggleDetailedSearch = () => {
    setDetailedSearch(prev => !prev)
  }

  const getCitiesFromUrl = () => {
    let cities = [];
    if(typeof window !== 'undefined'){
      const rx = /[toimitilat|toimistot|liiketilat|varastot|tuotantotilat]\/(.*)\?/ig;
      const arr = rx.exec(window.location.href);
      if (arr && (arr.length > 0)) {
        const splittedCities = arr[1].split(',');
        for(const c of splittedCities) {
          let cty = c.replace("toimitilat/", "");
          cty = cty.replace("toimistot/", "");
          cty = cty.replace("liiketilat/", "");
          cty = cty.replace("varastot/", "");
          cty = cty.replace("tuotantotilat/", "");
          cty = cty.replace("Toimitilat/", "");
          cty = cty.replace("Toimistot/", "");
          cty = cty.replace("Liiketilat/", "");
          cty = cty.replace("Varastot/", "");
          cty = cty.replace("Tuotantotilat/", "");
          const city = {
            city: cty,
            label: cty.charAt(0).toUpperCase() + cty.slice(1).toLowerCase()
          }
          cities.push(city);
        }
      }
    }
    
    return cities;
  }
  /*
    Set default values for all fields in the search box.
  */
  const getInitialValues = reset => {
    //If reset if enabled, a complete reset of the form is made, ignoring any existing SerachContext.filters
    const contextFilters = reset ? {} : searchContext.filters
    // If locations don't exist in contextFilters, check and fetch the cities in the URL
    if (!contextFilters.locations) {
      contextFilters.locations = getCitiesFromUrl();
    }
    const initVals = {
      types: R.pathOr([], ["types"], contextFilters),
      locations: R.pathOr([], ["locations"], contextFilters),
      workspaces: R.pathOr("", ["workspaces"], contextFilters),
      sizeMin: R.pathOr("", ["sizeMin"], contextFilters),
      sizeMax: R.pathOr("", ["sizeMax"], contextFilters),

      /* For the type specific filters, set all to default values or then previous serasch values */
      ...R.reduce(
        (acc, premisesTypeKey) => {
          const premisesType = R.pathOr(
            {},
            ["premisesType", premisesTypeKey],
            searchBoxConfiguration
          )
          acc[premisesType.sizeMinField] = R.pathOr(
            "",
            [premisesType.sizeMinField],
            contextFilters
          )
          acc[premisesType.sizeMaxField] = R.pathOr(
            "",
            [premisesType.sizeMaxField],
            contextFilters
          )
          R.pathOr([], ["filters"], premisesType).forEach(filterGroup => {
            R.pathOr([], ["criteria"], filterGroup).forEach(criteria => {
              acc[`${premisesType.type}-${criteria.value}`] = R.pathOr(
                false,
                [`${premisesType.type}-${criteria.value}`],
                contextFilters
              )
            })
          })
          return acc
        },
        {},
        R.keys(R.pathOr({}, ["premisesType"], searchBoxConfiguration))
      ),

      freeTextFilter: R.pathOr("", ["freeTxt"], contextFilters),

      /* Listing tags */
      ...R.reduce(
        (acc, otherTag) => {
          acc[otherTag.value] = R.pathOr(false, ["default"], otherTag)
          return acc
        },
        {},
        R.pathOr([], ["listingTypeTags"], searchBoxConfiguration)
      ),
      /* Other tags */
      ...R.reduce(
        (acc, otherTag) => {
          acc[otherTag.value] = R.pathOr(false, ["default"], otherTag)
          return acc
        },
        {},
        R.pathOr([], ["otherTags"], searchBoxConfiguration)
      ),
    }
    return initVals
  }

  const handleReset = setValues => {
    setValues(getInitialValues(true))
  }

  const classes = useStyles()

  const loadIndexes = () => {
    searchContext.loadIndexes();
  }

  preloadIndex && searchContext.preloadIndex(true);
  compLoaded && compLoaded();
  searchContext.setHeroLoaded(true);
  return (
    <Box
      className={classes.root}
      style={
        !fullWidth
          ? { padding: "1em 2em", borderRadius: "6px", marginBottom: "3rem", minHeight: '500px' }
          : { marginBottom: "2rem" }
      }
    >
      <Box className={classes.search}>
        <Container>
          <Formik
            initialValues={getInitialValues()}
            validate={values => {
              const errors = {}

              /* Size validation min must be smaller than max*/
              const invalidSizeRangeError =
                "Maksimipinta-ala pitää olla suurempi kuin minimipinta-ala."

              if (
                values.sizeMax !== "" &&
                values.sizeMax !== "" &&
                parseFloat(values.sizeMin) > parseFloat(values.sizeMax)
              ) {
                console.log("Size min/max error")
                errors.sizeMin = " "
                errors.sizeMax = " "
                errors.size = invalidSizeRangeError
              }
              return errors
            }}
            onSubmit={(values, { setSubmitting }) => {
              searchContext.updateFilters({}, values)
              setSubmitting(false)
              const nextUrl = generateURLFromFilters(values)

              let services = [];
              R.path(['liiketilat-Katutason liiketila'], values) && services.push('liiketilat-Katutason liiketila');
              R.path(['liiketilat-Kauppakeskuksessa oleva tila'], values) && services.push('liiketilat-Kauppakeskuksessa oleva tila');
              R.path(['liiketilat-Ravintolatilat'], values) && services.push('liiketilat-Ravintolatilat');
              R.path(['liiketilat-Terveys- ja hyvinvointitilat'], values) && services.push('liiketilat-Terveys- ja hyvinvointitilat');
              R.path(['toimistot-Aulapalvelu'], values) && services.push('toimistot-Aulapalvelu');
              R.path(['toimistot-Lounasravintola'], values) && services.push('toimistot-Lounasravintola');
              R.path(['toimistot-Vuokrattavat pysäköintipaikat'], values) && services.push('toimistot-Vuokrattavat pysäköintipaikat');
              R.path(['toimistot-Vuokrattavia neuvottelutiloja'], values) && services.push('toimistot-Vuokrattavia neuvottelutiloja');
              R.path(['toimistot-Sähköauton latauspaikat'], values) && services.push('toimistot-Sähköauton latauspaikat');
              R.path(['tuotantotilat-Nosto-ovet'], values) && services.push('tuotantotilat-Nosto-ovet');
              R.path(['tuotantotilat-Tilan korkeus yli 4 m'], values) && services.push('tuotantotilat-Tilan korkeus yli 4 m');
              R.path(['varastot-Lastauslaituri'], values) && services.push('varastot-Lastauslaituri');
              R.path(['varastot-Nosto-ovet'], values) && services.push('varastot-Nosto-ovet');
              R.path(['varastot-Tilan korkeus yli 4 m'], values) && services.push('varastot-Tilan korkeus yli 4 m');

              let saleOrRent = [];
              R.path(['rent'], values) && saleOrRent.push('rent');
              R.path(['sale'], values) && saleOrRent.push('sale');

              const dataLayerObject = {
                event : "searchResultsLoaded",
                sijainti : R.path(['locations'], values),
                tilatyyppi : R.path(['types'], values),
                pintaAlaMin: R.path(['sizeMin'], values),
                pintaAlaMax: R.path(['sizeMax'], values),
                henkilomaara: R.path(['workspaces'], values),
                palvelut: services,
                hakusana : R.path(['freeTextFilter'], values),
                listaustyyppi: saleOrRent,
                ymparistosertifikaati: R.path(['certificates'], values)
              };
          
              // Push datalayer object
              if (typeof window !== 'undefined') {
                if(window.dataLayer){

                  if(window.dataLayer.length > 0 && 
                    !R.equals(window.dataLayer[window.dataLayer.length - 1], dataLayerObject))
                  {
                    window.dataLayer.push(dataLayerObject);
                  }else if(window.dataLayer.length === 0){
                    window.dataLayer.push(dataLayerObject);
                  }

                }else{
                  window.dataLayer = [];
                  window.dataLayer.push(dataLayerObject);
                }
              }
              
              //If URL is same page stay, else navigate to new page.
              navigate(nextUrl)
            }}
          >
            {({
              values,
              errors,
              resetForm,
              setValues,
              submitForm,
              isSubmitting,
              isValid,
              setFieldValue,
            }) => {
              useEffect(() => {
                /* 
                      This is used as the filters from the pagecontext that need to be passed to the Formik form.
                      
                      When landing to a page, the form is rendered before the filters are corretly loaded and set, which means the form will 
                      have outdated values.

                      This hook will listen for the filters to be updated and then update the correct values to the Formik form. When a form is submitted, 
                      the 'values' on the form are set the the SearchContext.filters.

                      The idea is to have a flow that goes:
                      SearchContext.filters loaded to form.values -> 
                      Formik manages form.values and handles user input -> 
                      form is submitted -> 
                      form.values are saved to SearchContext.filters

                      I'm not 100% sure if this is the best way of handling the relationship between the SearchContext.filters and the form values. 

                    */
                const fields = {
                  ...getInitialValues(),
                  ...searchContext.filters,
                }
                setValues(fields)
              }, [searchContext.filters])

              /* 
                    Input Handlers
                    Formik/Material UI bindings exlcude the onChange Formik attribute, 
                    which allows for handling data directly entered to the form. These effect 
                    hooks are a workaround to handle changes for specific data. It's not the 
                    best way to do this but there isn't the time to work deeply with the 
                    form and data handling.
                  */
              useEffect(() => {
                /* 
                      Types cleaning 

                      If everything is unselected, set types to ["toimitilat"]
                      If one type is selected just leave the types as is
                      If more than one type is defined, make sure "toimitilat is not included"

                    */
                const newTypes =
                  R.pathOr([], ["types"], values).length < 1
                    ? ["toimitilat"]
                    : //( R.pathOr([],["types"],values).length === 1 && values.types[0] !== "toimitilat") ? values.types :
                    R.pathOr([], ["types"], values).length > 1
                    ? values.types.filter(t => t !== "toimitilat")
                    : null

                // Check if update is needed (important to avoid infinite loops)
                if (
                  !!newTypes &&
                  !R.equals(values.types.sort(), newTypes.sort())
                ) {
                  setFieldValue("types", newTypes)
                }
              }, [values.types])

              // Make sure that if more than one premises type is selected the only sqm size is set (not workspaces)
              useEffect(() => {
                if (
                  R.pathOr([], ["types"], values).length !== 1 ||
                  R.pathOr([], ["types"], values).indexOf("toimistot") < 0
                ) {
                  setFieldValue("workspaces", "")
                }
              }, [values.types])

              /* 
                    Create a listener to handle numeric inputs for all size fields 
                    This will make sure they only contain numbers & have a max length
                  */
              const additonalFields = R.keys(
                R.pathOr({}, ["premisesType"], searchBoxConfiguration)
              ).reduce((acc, key) => {
                if (
                  R.path(
                    ["premisesType", key, "sizeMinField"],
                    searchBoxConfiguration
                  )
                )
                  acc.push(
                    R.path(
                      ["premisesType", key, "sizeMinField"],
                      searchBoxConfiguration
                    )
                  )
                if (
                  R.path(
                    ["premisesType", key, "sizeMaxField"],
                    searchBoxConfiguration
                  )
                )
                  acc.push(
                    R.path(
                      ["premisesType", key, "sizeMaxField"],
                      searchBoxConfiguration
                    )
                  )
                return acc
              }, [])

              R.concat(["sizeMin", "sizeMax"], additonalFields).forEach(
                value => {
                  useEffect(() => {
                    const formattedValue = formatSizeInput(values[value], 5)
                    // Check if update is needed (important to avoid infinite loops)
                    if (formattedValue !== values[value])
                      setFieldValue(value, formattedValue)
                  }, [values[value]])
                }
              )

              function formatSizeInput(value, maxLength) {
                // Format input:
                // 1. Remove prefixing zero
                // 2. Ignore non-numeric input
                // 3. Enforce maximum length

                // By default, the field contains an empty string.
                if (value === "") return value

                // 1 & 2
                if (value) {
                  let formatedValue = `${value}`
                    .replace(/^0+/, "")
                    .replace(/[^0-9]/g, "")
                  // 3
                  if (formatedValue > maxLength) {
                    formatedValue = formatedValue.substr(0, maxLength)
                  }
                  // Update value
                  return formatedValue || "0"
                } else {
                  // If value is in another state, return 0
                  return "0"
                }
              }

              return (
                <React.Fragment>
                  {/* 
                      TOP ROW - type, location filters, size 
                      This is always shown and doesn't collapse
                    */}
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    spacing={3}
                    onClick={() => loadIndexes()}
                  >
                    <Grid item xs={12}>
                      <Box className={classes.formField}>
                        <label className={classes.fieldLabel}>
                          {R.path(
                            ["locationFiltersHeading"],
                            searchBoxConfiguration
                          )}
                          <Field
                            component={AutocompleteField}
                            name="locations"
                            type="text"
                            className={`${classes.textField} ${classes.locationField}`}
                            options={searchContext.autocompleteFilters}
                            indexesLoaded={searchContext.indexesLoaded}
                            getOptionLabel={option => option.label}
                            /* Slight different to the standard Field format as Autocomplete is a wrapper field */
                            textFieldProps={{
                              margin: "dense",
                              variant: "outlined",
                              placeholder: "Kaupunki, postinumero tai alue",
                              /*InputProps: {
                                  startAdornment: (<InputAdornment position="start"><LocationOnIcon className={classes.locationInputAdornment}/></InputAdornment>)
                                }*/ // Stops autocomplete working
                            }}
                          />
                        </label>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      /*xs={12}
                        sm={6}
                        md={2}*/
                      style={{ maxWidth: "100%", flexGrow: "1" }}
                    >
                      <FormControl>
                        <label className={classes.fieldLabel}>
                          {R.path(
                            ["premisesTypeHeading"],
                            searchBoxConfiguration
                          )}
                          <Field
                            component={Select}
                            name="types"
                            className={classes.selectField}
                            margin="dense"
                            variant="outlined"
                            multiple
                            IconComponent={ExpandMoreIcon}
                            MenuProps={{
                              getContentAnchorEl: null,
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                            }}
                            renderValue={selected => {
                              // This chooses how to render the values in the box view
                              try {
                                // If the selected value are only "Toimitilat" display the placeholder "Kaikki"
                                if (
                                  selected.length === 1 &&
                                  selected[0] === "toimitilat"
                                )
                                  return "Kaikki"
                                // Else return selected values (minus toimitilat if it is set
                                // (which it shouldn't be at this point but this just makes sure)
                                return selected
                                  .filter(t => !!t && t !== "toimitilat")
                                  .map(t => toTitleCase(t))
                                  .join(", ")
                              } catch (e) {
                                return ""
                              }
                            }}
                          >
                            {R.keys(
                              R.pathOr(
                                {},
                                ["premisesType"],
                                searchBoxConfiguration
                              )
                            ).map((key, idx) => (
                              <MenuItem
                                key={`type-${idx}`}
                                value={R.path(
                                  ["premisesType", key, "type"],
                                  searchBoxConfiguration
                                )}
                              >
                                <Checkbox
                                  color="primary"
                                  checked={
                                    R.pathOr([], ["types"], values).indexOf(
                                      R.path(
                                        ["premisesType", key, "type"],
                                        searchBoxConfiguration
                                      )
                                    ) > -1
                                  }
                                />
                                <ListItemText primary={key} />
                              </MenuItem>
                            ))}
                          </Field>
                        </label>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      /*xs={12}
                        sm={4}
                        md={5}*/
                    >
                      <Box className={classes.sizeFiltersHeading}>
                        <fieldset>
                          <legend id="sizeFilterHeading">
                            {R.path(
                              ["sizeFiltersHeading"],
                              searchBoxConfiguration
                            )}
                          </legend>
                          <Field
                            component={TextField}
                            name="sizeMin"
                            placeholder="Min"
                            type="text" //remove default scroll behaviour, will do form validation independently via handleInput
                            className={`${classes.textField} ${classes.numberField}`}
                            // If a workspace amount has been defined, then size must be disabled
                            disabled={
                              Object.keys(R.pathOr({}, ["workspaces"], values))
                                .length > 0
                                ? true
                                : false
                            }
                            margin="dense"
                            variant="outlined"
                            aria-describedby="sizeFilterHeading"
                            InputProps={{
                              "aria-label": "Min square meters",
                              endAdornment: (
                                <InputAdornment
                                  className={classes.sizeInputAdornment}
                                  position="end"
                                >
                                  m<sup>2</sup>
                                </InputAdornment>
                              ),
                            }}
                          />
                          <RemoveIcon className={classes.rangeIcon} />
                          <Field
                            component={TextField}
                            name="sizeMax"
                            type="text" //remove default scroll behaviour, will do form validation independently via handleInput
                            placeholder="Max"
                            className={`${classes.textField} ${classes.numberField}`}
                            // If a workspace amount has been defined, then size must be disabled
                            disabled={
                              Object.keys(R.pathOr({}, ["workspaces"], values))
                                .length > 0
                                ? true
                                : false
                            }
                            margin="dense"
                            variant="outlined"
                            aria-describedby="sizeFilterHeading"
                            InputProps={{
                              "aria-label": "Max square meters",
                              endAdornment: (
                                <InputAdornment
                                  className={classes.sizeInputAdornment}
                                  position="end"
                                >
                                  m<sup>2</sup>
                                </InputAdornment>
                              ),
                            }}
                          />
                          {errors && errors.size && ( 
                            <Typography
                              className={styles.errorText}
                              variant="body2"
                              component="div"
                            >
                              {errors.size}
                            </Typography>
                          )}
                        </fieldset>
                      </Box>
                    </Grid>
                    {/* Only show this field when "toimistot" is selected and nothing else is */}
                    {R.pathOr([], ["types"], values).indexOf("toimistot") >
                      -1 &&
                      R.pathOr([], ["types"], values).length === 1 && (
                        <Grid
                          item
                          /*xs={12}
                        sm={6}
                        md={2}*/
                        >
                          <FormControl>
                            <label className={classes.fieldLabel}>
                              {R.path(
                                ["workspaceAmountHeading"],
                                searchBoxConfiguration
                              )}
                              <Field
                                component={Select}
                                name="workspaces"
                                className={classes.selectField}
                                IconComponent={ExpandMoreIcon}
                                margin="dense"
                                variant="outlined"
                                displayEmpty
                                // If a min/max size is defined then the workspaces amount must be disabled
                                // Also if min/max is defined specifcally in the toimisto subheader
                                disabled={
                                  values.sizeMin !== "" ||
                                  values.sizeMax !== "" ||
                                  values.toimistotSizeMin !== "" ||
                                  values.toimistotSizeMax !== ""
                                    ? true
                                    : false
                                }
                                MenuProps={{
                                  getContentAnchorEl: null,
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                }}
                              >
                                <MenuItem
                                  key={"wspaces-placeholder"}
                                  value={""}
                                  disabled
                                >
                                  <ListItemText
                                    className={`${classes.selectItem} ${classes.placeholder}`}
                                    primary={R.path(
                                      ["workspaceAmountPlaceholder"],
                                      searchBoxConfiguration
                                    )}
                                  />
                                </MenuItem>
                                {R.keys(
                                  R.pathOr(
                                    {},
                                    ["workspaceAmount"],
                                    searchBoxConfiguration
                                  )
                                ).map((key, idx) => (
                                  <MenuItem
                                    key={`wspaces-${idx}`}
                                    value={R.path(
                                      ["workspaceAmount", key, "value"],
                                      searchBoxConfiguration
                                    )}
                                  >
                                    <ListItemText
                                      className={classes.selectItem}
                                      primary={toTitleCase(
                                        R.path(
                                          ["workspaceAmount", key, "label"],
                                          searchBoxConfiguration
                                        )
                                      )}
                                    />
                                  </MenuItem>
                                ))}
                              </Field>
                            </label>
                          </FormControl>
                        </Grid>
                      )}
                  </Grid>
                  {/* 
                      DETAILED SEARCH FIELDS - expands the second & thrid rows
                    */}
                  <Collapse
                    in={detailedSearch}
                    className={classes.detailedSearchContainer}
                  >
                    <Section dividerTop={true} dividerBottom={false}>
                      {/* 
                          MIDDLE ROW - filters for type(s) 
                        */}
                      <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                      >
                        {R.keys(
                          R.pathOr({}, ["premisesType"], searchBoxConfiguration)
                        ).map((key, idx) => {
                          /* If the container is hidden, then skip all the evaluation & rendering */
                          if (!detailedSearch) {
                            return null
                          }

                          const premisesType = R.path(
                            ["premisesType", key],
                            searchBoxConfiguration
                          )

                          /* Check configuration should be shown*/
                          if (
                            values.types &&
                            !values.types.includes("toimitilat") &&
                              !values.types.includes(premisesType.type)
                          ) {
                            return null
                          }

                          /* Check if the required configuration is defined */
                          if (
                            !R.path(["filters"], premisesType) ||
                            R.pathOr([], ["filters"], premisesType).length <= 0
                          ) {
                            return null
                          }

                          return (
                            <Grid key={`${key}-${idx}`} sm={12} md={6} item>
                              <Typography
                                className={classes.criteriaHeader}
                                variant="h5"
                                component="span"
                              >
                                {premisesType.heading}
                              </Typography>
                              {R.pathOr([], ["types"], values).length > 1 && (
                                /* If more than one type selected show additional size filters for each type.
                                The actual search function will determine whih size fields to use.
                                Also, if the area is for type of "Toimistot AND number of workspaces is defined 
                                then this is disabled. */
                                <Box className={classes.sizeFiltersHeading}>
                                  <fieldset>
                                    <Typography
                                      id={`${premisesType.sizeHeading}`}
                                      className={classes.tagCheckboxHeader}
                                      variant="h5"
                                      component="legend"
                                    >
                                      {premisesType.sizeHeading}
                                    </Typography>
                                    <Field
                                      component={TextField}
                                      name={premisesType.sizeMinField}
                                      placeholder="Min"
                                      type="text" //remove default scroll behaviour, will do form validation independently via hooks
                                      className={`${classes.textField} ${classes.numberField}`}
                                      margin="dense"
                                      variant="outlined"
                                      aria-describedby={`${premisesType.sizeHeading}`}
                                      // If a workspace amount has been defined AND this size is for toimistot, then size must be disabled
                                      disabled={
                                        premisesType.type === "toimistot" &&
                                        Object.keys(
                                          R.pathOr({}, ["workspaces"], values)
                                        ).length > 0
                                          ? true
                                          : false
                                      }
                                      InputProps={{
                                        "aria-label": "Min square meters",
                                        endAdornment: (
                                          <InputAdornment
                                            className={
                                              classes.sizeInputAdornment
                                            }
                                            position="end"
                                          >
                                            m<sup>2</sup>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                    <RemoveIcon className={classes.rangeIcon} />
                                    <Field
                                      component={TextField}
                                      name={premisesType.sizeMaxField}
                                      type="text" //remove default scroll behaviour, will do form validation independently via hooks
                                      placeholder="Max"
                                      className={`${classes.textField} ${classes.numberField}`}
                                      margin="dense"
                                      variant="outlined"
                                      aria-describedby={`${premisesType.sizeHeading}`}
                                      // If a workspace amount has been defined AND this size is for toimistot, then size must be disabled
                                      disabled={
                                        premisesType.type === "toimistot" &&
                                        Object.keys(
                                          R.pathOr({}, ["workspaces"], values)
                                        ).length > 0
                                          ? true
                                          : false
                                      }
                                      InputProps={{
                                        "aria-label": "Max square meters",
                                        endAdornment: (
                                          <InputAdornment
                                            className={
                                              classes.sizeInputAdornment
                                            }
                                            position="end"
                                          >
                                            m<sup>2</sup>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </fieldset>
                                </Box>
                              )}

                              {R.pathOr([], ["filters"], premisesType).map(
                                (filterGroup, idx) => {
                                  return (
                                    <Box
                                      key={idx}
                                      className={classes.tagCheckboxContainer}
                                    >
                                      {filterGroup.heading && (
                                        <Typography
                                          className={classes.tagCheckboxHeader}
                                          variant="h5"
                                          component="span"
                                        >
                                          {filterGroup.heading}
                                        </Typography>
                                      )}
                                      {R.pathOr(
                                        [],
                                        ["criteria"],
                                        filterGroup
                                      ).map(criteria => (
                                        <Field
                                          key={`${premisesType.type}-${criteria.value}`}
                                          component={CheckboxWithLabel}
                                          type="checkbox"
                                          name={`${premisesType.type}-${criteria.value}`}
                                          Label={{ label: `${criteria.name}` }}
                                          size={"small"}
                                          //checkedIcon={<CheckBoxIcon fontSize="small" />}
                                          color="primary"
                                          disableRipple
                                        />
                                      ))}
                                    </Box>
                                  )
                                }
                              )}
                            </Grid>
                          )
                        })}
                      </Grid>
                    </Section>
                    <Section dividerTop={true} dividerBottom={false}>
                      {/* 
                          BOTTOM ROW - free text(!), misc tags 
                        */}
                      <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                      >
                        <Grid item style={{ flexGrow: 1, marginRight: "1rem" }}>
                          <Box className={classes.formField}>
                            <label className={classes.fieldLabel}>
                              {R.path(
                                ["freeTextHeading"],
                                searchBoxConfiguration
                              )}
                              <Field
                                component={TextField}
                                name="freeTextFilter"
                                type="text"
                                placeholder={R.path(
                                  ["freeTextPlaceholder"],
                                  searchBoxConfiguration
                                )}
                                className={`${classes.textField} ${classes.freeTextField}`}
                                margin="dense"
                                variant="outlined"
                              />
                            </label>
                          </Box>
                        </Grid>

                        <Grid item>
                          <Box className={classes.tagCheckboxContainer}>
                            {R.pathOr(
                              [],
                              ["listingTypeTags"],
                              searchBoxConfiguration
                            ).map((listingType, idx) => (
                              <Field
                                key={idx}
                                component={CheckboxWithLabel}
                                type="checkbox"
                                name={listingType.value}
                                Label={{ label: `${listingType.name}` }}
                                size={"small"}
                                //checkedIcon={<CheckBoxIcon fontSize="small" />}
                                color="primary"
                                disableRipple
                              />
                            ))}
                          </Box>
                        </Grid>

                        <Grid item>
                          {R.pathOr(
                            [],
                            ["otherTags"],
                            searchBoxConfiguration
                          ).map((otherTag, idx) => (
                            <Field
                              key={idx}
                              component={CheckboxWithLabel}
                              type="checkbox"
                              name={otherTag.value}
                              Label={{ label: `${otherTag.name}` }}
                              size={"small"}
                              //checkedIcon={<CheckBoxIcon fontSize="small" />}
                              color="primary"
                              disableRipple
                            />
                          ))}
                        </Grid>
                      </Grid>
                    </Section>
                  </Collapse>

                  {/* Form controls - submit form button, detailed search button*/}
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    spacing={5}
                    className={classes.buttonContainer}
                  >
                    <Grid item xs={12}>
                      <Box
                        className={classes.toggleDetailedSearch}
                        onClick={toggleDetailedSearch}
                      >
                        {!!detailedSearch ? <RemoveIcon /> : <AddIcon />}
                        <Typography variant="body2">
                          {!!detailedSearch
                            ? "Piilota tarkennettu haku"
                            : "Tarkenna haku"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box
                        className={classes.toggleDetailedSearch}
                        onClick={() => handleReset(setValues)}
                      >
                        <Typography variant="body2">Tyhjennä kentät</Typography>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="secondary"
                        className={classes.submitButton}
                        disabled={!isValid || isSubmitting}
                        onClick={submitForm}
                      >
                        Näytä kohteet
                      </Button>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )
            }}
          </Formik>
        </Container>
      </Box>
    </Box>
  )
}

export default SearchBox
